// CORE
import Vue from 'vue'
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'

// STORE
import store from '@/store'

//INTERFACE
import {
  ContactResource,
  ContactsCarParkCarParkIdGetRequest,
  ContactsDriverCarIdGetRequest,
} from '../types'

@Module({
  dynamic: true,
  name: 'contacts',
  store,
  namespaced: true,
})

class Policies extends VuexModule {
  /**
   * Работа со статусами - чипсами
   */

  statusId: number | string | undefined = ''

  @Mutation
  setStatusId (payload: number | string | undefined) {
    Vue.set(this, 'statusId', payload)
  }

  /**
   * Работа с контактами
   */

  contacts: ContactResource[] = []

  @Action({ rawError: true })
  async getContacts (payload: any) {
    if (payload.role === 'driver') {
      const { data } = await ContactsDriverCarIdGetRequest(payload.entity)
      this.setContacts(data)
    } else {
      const { data } = await ContactsCarParkCarParkIdGetRequest(payload.entity)
      this.setContacts(data)
    }
  }

  @Mutation
  setContacts (payload: any) {
    Vue.set(this, 'contacts', payload)
  }

}

const ContactsModule = getModule(Policies)

export default ContactsModule
