























// CORE
import { Component, Mixins } from 'vue-property-decorator'

// COMPONENTS
import ContactCard from '@/components/cards/ContactCard.vue'
import NotifyMixin from '@/mixins/NotifyMixin'
import DefaultLayout from '@/components/layouts/DefaultLayout.vue'
import CaptionCard from '@/components/cards/CaptionCard.vue'

//STORE
import ContactsModule from '@/store/modules/contacts'
import SystemMixin from '@/mixins/SystemMixin'


@Component({
  components: {
    DefaultLayout,
    ContactCard,
    CaptionCard,
  },
})
export default class ContactsLayout extends Mixins(NotifyMixin, SystemMixin) {

  private get activeStatus (): number | string | undefined {
    return ContactsModule.statusId
  }

  private set activeStatus (value: number | string | undefined) {
    ContactsModule.setStatusId(value)
  }

  private get contacts () {
    return ContactsModule.contacts
  }

  private changeStatus (value: number) {
    ContactsModule.setStatusId(value)
  }

  private created () {
    ContactsModule.getContacts({ role: this.role, entity: +this.entity })
    this.activeStatus = 'moscow'
  }
}
