



































import { Component, Prop, Mixins } from 'vue-property-decorator'

import Card from '@/components/cards/Card.vue'
import NotifyMixin from '@/mixins/NotifyMixin'
import { handlePhoneNumber, handleWorkNumber } from '@/utils/functions'
import { ContactResource } from '@/store/types'

@Component({
  components: {
    Card,
  },
})
export default class ContactCard extends Mixins(NotifyMixin) {
  @Prop({ required: true })
  private card!: ContactResource

  private get workNumberLink (): string {
    return handlePhoneNumber('link', this.card.phoneWithCode.replace('#', 'p')) ?? ''
  }

  private get workNumberView (): string {
    return handlePhoneNumber('view', handleWorkNumber(this.card.phoneWithCode)?.main ?? '') ?? ''
  }

  private get phoneCode (): string {
    return handleWorkNumber(this.card.phoneWithCode)?.additional ?? ''
  }

  // обработка строки рабочего номера для вывода на экран
  private handleWorkNumber = handleWorkNumber
  private handlePhoneNumber = handlePhoneNumber
}
